import React, { useState } from "react";
/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { motion, AnimatePresence } from "framer-motion";
import { Page } from "../carousel/Carousel";
import { Button, Image, Visibility } from "../core";
import { alpha } from "@theme-ui/color";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MotionBox = motion.custom(Box);

export default function Carousel({ images, sx, ...props }) {
  const [currentPage, setCurrentPage] = useState(0);
  const scale = 0.5;
  const width = 66;
  const arrowPos = (100 - 66) / 2;

  const pages = React.useMemo(
    () =>
      images
        ? images
            .filter((image) => image)
            .map((image, i) => (
              <motion.div
                key={i}
                sx={{
                  height: `calc((73/120)*${width}vw)`,
                  width: `${width}vw`,
                  cursor: "grab",
                  backgroundColor: alpha("primary", 0.25),
                }}
              >
                <GatsbyImage
                  image={getImage(image)}
                  alt={`Image carousel ${i + 1}`}
                  style={{ height: "100%", pointerEvents: "none" }}
                />
              </motion.div>
            ))
        : [],
    [scale, images]
  );

  return (
    <Box as="section" sx={{ position: "relative", ...sx }} {...props}>
      <Page
        currentPage={currentPage}
        onChangePage={(index) => {
          setCurrentPage(index);
        }}
        originX={0.5}
        style={{
          height: `calc((73/120)*${width}vw)`,
          width: "100%",
        }}
      >
        {pages}
      </Page>

      {pages.length > 1 && (
        <>
          <AnimatePresence>
            {currentPage > 0 && (
              <Visibility display={["hidden", null, "visible"]}>
                <MotionBox
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <StyledButton
                    id="btn-carousel-prev"
                    sx={{
                      position: "absolute",
                      top: "calc(50% - 32px)",
                      left: `calc(${arrowPos}vw - 92px)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (currentPage > 0) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                  >
                    <Image src="/img/fleche.svg" />
                  </StyledButton>
                </MotionBox>
              </Visibility>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {currentPage < pages.length - 1 && (
              <Visibility display={["hidden", null, "visible"]}>
                <MotionBox
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <StyledButton
                    id="btn-carousel-next"
                    sx={{
                      position: "absolute",
                      top: "calc(50% - 32px)",
                      right: `calc(${arrowPos}vw - 92px)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (currentPage < pages.length - 1) {
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                  >
                    <Image
                      src="/img/fleche.svg"
                      sx={{ transform: "rotate(180deg)" }}
                    />
                  </StyledButton>
                </MotionBox>
              </Visibility>
            )}
          </AnimatePresence>
        </>
      )}
    </Box>
  );
}

const MotionButton = motion.custom(Button);

const StyledButton = React.forwardRef(({ sx, ...props }, ref) => (
  <MotionButton
    ref={ref}
    backgroundColor="secondary"
    sx={{ width: "64px", height: "64px", ...sx }}
    {...props}
  />
));
