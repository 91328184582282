import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import { Box } from "theme-ui";
import { useResponsiveValue } from "../hooks/breakpoints";
import Page from "../components/Page";
import SEO from "../components/SEO";
import {
  Badge,
  Container,
  Flex,
  FlexGrid,
  Heading,
  Text,
  Visibility,
  Button,
  Link,
} from "../components/core";
import { Arrow, Location, Vague } from "../components/svg";
import { GatsbyImage, getSrc, getImage } from "gatsby-plugin-image";
import { alpha } from "@theme-ui/color";
import { InView } from "react-intersection-observer";
import Carousel from "../components/event/Carousel";
import { CardContent } from "../components/event/CardContent";
import { Card } from "../components/Card";
import Contact from "../components/Contact";
import { HTMLContent } from "../components/Content";
import filter from "lodash/filter";
import { Highlights } from "../components/Highlights";
import useThemeColor from "../hooks/useThemeColor";
import { motion } from "framer-motion";
import moment from "../helpers/moment";

const Event = ({ data }) => {
  const { allMarkdownRemark, markdownRemark: event } = data;
  const { edges: events } = allMarkdownRemark;

  const highlightsWithoutNull = filter(
    event.frontmatter.highlights,
    (highlight) => highlight
  );

  const value = useResponsiveValue([12, 6, 4]);

  const variantsEvents = {
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: (i % (12 / value)) * 0.4,
      },
    }),
    hidden: { opacity: 0 },
  };

  const searchRegExp = /<a href=/gi;
  const replaceWith = '<a target="_blank" href=';
  const eventHtml = useMemo(
    () => event.html.replace(searchRegExp, replaceWith),
    [event.html]
  );
  const dateIsAfter = moment(event.frontmatter.date, "DD MMMM YYYY").isAfter();
  const dateIsSame = moment(event.frontmatter.date).isSame(moment(), "day");

  return (
    <Page
      sx={{
        backgroundImage: "url(/img/bg-event.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
        backgroundSize: ["100vw auto", null, "50vw auto"],
        backgroundColor: "background3",
      }}
    >
      <SEO
        title={event.frontmatter.title}
        description={`Le ${event.frontmatter.date} à ${event.frontmatter.place}`}
        image={getSrc(event.frontmatter.image)}
      />
      <Container
        variant="containerLg"
        sx={{ marginTop: [3, null, 5], marginBottom: 2 }}
      >
        <Visibility display={["hidden", "visible", null]}>
          <StyledReturn
            to={
              event.frontmatter.numero
                ? `/evenements?scrollTo=card-${event.frontmatter.numero}`
                : "/evenements"
            }
          />
        </Visibility>
        <Visibility display={["visible", "hidden", null]}>
          <Button
            id="btn-return"
            color="primary"
            width={65}
            to={
              event.frontmatter.numero
                ? `/evenements?scrollTo=card-${event.frontmatter.numero}`
                : "/evenements"
            }
            sx={{ borderRadius: "50%", padding: 2, display: "flex" }}
          >
            <Arrow
              height="14px"
              width="10px"
              color="white"
              sx={{ transform: "translateX(-1px) translateY(1px)" }}
            />
          </Button>
        </Visibility>
      </Container>
      <Container variant="containerSm" sx={{ marginBottom: 5 }}>
        <Box
          sx={{ marginTop: 3, textAlign: "center" }}
          direction={["column", "column", "column"]}
          positions={["center", "center"]}
        >
          <Flex direction="row" sx={{ marginBottom: 1 }}>
            {event.frontmatter.type === "Ponctuel" &&
            (dateIsAfter || dateIsSame) ? (
              <Badge sx={{ marginRight: 2 }}>
                {dateIsAfter ? "À venir" : "Ce jour"}
              </Badge>
            ) : null}
            {event.frontmatter.type === "Permanent" ? (
              <Badge sx={{ marginRight: 2 }}>Inscris-toi</Badge>
            ) : null}
            <Text variant="h6" uppercase color="primary">
              {event.frontmatter.type === "Permanent"
                ? "Toute l’année"
                : event.frontmatter.date}
            </Text>
          </Flex>
          <Box xs={{ display: "inline" }}>
            <Heading as="h1" sx={{ marginBottom: 1, display: "inline" }}>
              {event.frontmatter.title}
              {event.frontmatter.numero ? (
                <Badge
                  sx={{
                    marginLeft: 2,
                    backgroundColor: "secondary",
                    position: "relative",
                    top: "13px",
                    py: "4px",
                    verticalAlign: "text-top",
                  }}
                >
                  <Heading
                    variant="h4"
                    as="span"
                    fontWeight="bold"
                    sx={{ lineHeight: "32px" }}
                  >
                    #{event.frontmatter.numero}
                  </Heading>
                </Badge>
              ) : null}
            </Heading>
          </Box>
          <Flex>
            <Location />
            <Text fontWeight="semiBold" sx={{ marginLeft: 1, opacity: 0.7 }}>
              {event.frontmatter.place}
            </Text>
          </Flex>
        </Box>
      </Container>

      {highlightsWithoutNull.length > 0 && (
        <Box sx={{ position: "relative", marginBottom: [8, null, 10] }}>
          <Highlights
            sx={{
              margin: "auto",
            }}
          >
            {highlightsWithoutNull.map(({ number, label }, index) => (
              <Highlights.Item key={index}>
                <Highlights.Item.Number variant="h2">
                  {number}
                </Highlights.Item.Number>
                <Highlights.Item.Label>{label}</Highlights.Item.Label>
              </Highlights.Item>
            ))}
          </Highlights>
        </Box>
      )}
      <Container variant="containerSm" sx={{ paddingBottom: 5 }}>
        <Box>
          <GatsbyImage
            image={getImage(event.frontmatter.image)}
            alt={`Image principal ${event.frontmatter.title}`}
            backgroundColor={alpha("primary", 0.25)}
            style={{ marginBottom: "40px" }}
            loading="eager"
          />
          <HTMLContent>{eventHtml}</HTMLContent>
        </Box>
      </Container>
      {event.frontmatter.images && (
        <Box sx={{ marginBottom: [8, null, 13] }}>
          <Carousel images={event.frontmatter.images} />
        </Box>
      )}
      {events && (
        <Container variant="containerLg" sx={{ paddingBottom: [8, null, 13] }}>
          <Box sx={{ textAlign: "center" }}>
            <Heading as="h2" variant="h1" color="secondary">
              Nos autres événements
            </Heading>
            <Vague
              sx={{
                marginTop: 6,
                marginBottom: 7,
              }}
            />
          </Box>
          <FlexGrid positions={["flex-start", "stretch"]} columns={[12, 6, 4]}>
            {events.map(({ node: event }, index) => (
              <Box key={event.id}>
                <InView triggerOnce threshold={0.3}>
                  {({ inView, ref }) => (
                    <Card
                      ref={ref}
                      custom={index}
                      variants={variantsEvents}
                      animate={inView ? "visible" : "hidden"}
                      to={event.fields.slug}
                    >
                      <Card.Image
                        image={getImage(event.frontmatter.image)}
                        imageLoading={index < 6 ? "eager" : "lazy"}
                        alt={`Image card ${event.frontmatter.title}`}
                      />
                      <CardContent
                        numero={event.frontmatter.numero}
                        date={event.frontmatter.date}
                        title={event.frontmatter.title}
                        place={event.frontmatter.place}
                      />
                    </Card>
                  )}
                </InView>
              </Box>
            ))}
          </FlexGrid>
          <Button
            sx={{
              marginTop: 8,
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
            }}
            to="/evenements"
          >
            Voir tous les événements
          </Button>
        </Container>
      )}
      <Contact />
    </Page>
  );
};

export default Event;

const StyledReturn = ({ sx, children, ...props }) => {
  const [isHover, setIsHover] = useState(false);
  const colorSecondary = useThemeColor("secondary");
  const color = useThemeColor("primary");
  return (
    <Link
      sx={{
        fontWeight: "bold",
        textTransform: "uppercase",
        ...sx,
      }}
      variant="footer"
      {...props}
    >
      <motion.span
        initial={{
          color,
        }}
        whileHover={{
          color: colorSecondary,
        }}
        transition={{
          duration: 0.2,
        }}
        onHoverStart={() => {
          setIsHover(true);
        }}
        onHoverEnd={() => {
          setIsHover(false);
        }}
      >
        <Arrow
          color={isHover ? "secondary" : "primary"}
          sx={{ marginRight: 1 }}
        />
        Retour
      </motion.span>
    </Link>
  );
};

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        numero
        title
        place
        type
        date(formatString: "DD MMMM YYYY", locale: "fr")
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 768)
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        highlights {
          highlights1 {
            number
            label
          }
          highlights2 {
            number
            label
          }
          highlights3 {
            number
            label
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        id: { ne: $id }
        frontmatter: {
          templateKey: { eq: "event" }
          visible: { eq: true }
          type: { eq: "Ponctuel" }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            numero
            title
            place
            templateKey
            date(formatString: "DD MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 900
                  aspectRatio: 1.666
                )
              }
            }
          }
        }
      }
    }
  }
`;
